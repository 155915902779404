import React from "react";
import {
  Card,
  Spacer,
} from "../../components";

export const EnConstruccion = (props) => {
  /** UI */
  return (
    <>
      <Spacer size={40} />
      <Card titulo={props.titulo}>
        <div className="en-construccion" />
        <p className="en-construccion-label">Página en construcción</p>
      </Card>
    </>
  );
};
export default EnConstruccion;