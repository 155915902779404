import React from "react";

export const Spacer = ({
  size
}) => {

  return (
    <div style={{
        width: "100%",
        height: `${size}px`
    }}
    />
  );
};

export default Spacer;
