import { useLocation } from "react-router-dom";

export const useLocalStorage = () => {
  
  const location = useLocation();

  const get = (key, global = false) => {
    let hashHasParams = (location?.hash.indexOf('?') > 0);
    let hash = location?.hash.substring(0, hashHasParams ? location?.hash.indexOf('?') : location?.hash.length).replace('#', '_');
    return localStorage.getItem(`${global ? '' : `${hash}_`}${key}`) || "";
  }
  
  const set = (key, value) => {
    let hashHasParams = (location?.hash.indexOf('?') > 0);
    let hash = location?.hash.substring(0, hashHasParams ? location?.hash.indexOf('?') : location?.hash.length).replace('#', '_');
    localStorage.setItem(`${hash}_${key}`, value);
  }

  //Estados y funciones utilizables del hook
  return {
    get,
    set
  };
};

export default useLocalStorage;
