import React, { useState } from "react";
import { QrReader } from 'react-qr-reader';

export const QrScanner = ({
  icon,
  text,
  url,
  onResult,
  enabled
}) => {
  const [scanValue, setScanValue] = useState(null);

  return (
    <div className="contact-card">
      <div className="contact-card-desc">
        <a className={"contact-card-link"} href={url} target={"_blank"}>{icon} {text}</a>
        {enabled &&
          <>
            <QrReader
              style={{ width: '100%' }}
              onResult={(result, error) => {
                if (!!result && onResult) {
                  console.log('onResult result.text', result?.text)
                  setScanValue(result?.text);
                  onResult(result?.text);
                }
                if (!!error) {
                  console.info(error);
                }
              }}
            />
            <p>{`scanValue: ${scanValue}`}</p>
          </>
        }
      </div>
    </div>
  );
};

export default QrScanner;
