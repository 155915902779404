export const colaboradorStatus = [
    {
      desc: "Desconocido",
      color: "#FFFFFF"
    },
    {
      desc: "Activo",
      color: "#386DA1"
    },
    {
      desc: "Inactivo",
      color: "#00000070"
    },
    {
      desc: "Suspendido",
      color: "#FF0000D0"
    }
  ]