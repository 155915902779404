import React from "react";

export const WebSelect = ({
  name,
  onChange,
  dataSource,
  rowKey,
  rowNombre,
  handleFocused,
  isFocused,
  value
}) => {

  const handleControlChange = (e, value) => {
    if(onChange) {
      onChange(e, value);
    }
  }

  return (
    <select
      id={name}
      onChange={handleControlChange}
      className={`captura${isFocused ? " captura-focused" : ""}`}
      defaultValue={'DEFAULT'}
      onFocus={() => handleFocused(true)}
      onBlur={() => handleFocused(false)}
      value={value}
    >
      <option value={"DEFAULT"} disabled> Selecciona un valor </option>
      { dataSource.map( item => <option value={item[rowKey || 'id']}>{item[rowNombre || 'nombre']}</option> ) } 
    </select>
  );
};

export default WebSelect;
