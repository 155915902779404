import React from "react";
import { Outlet } from "react-router-dom";
import AppBar from "../../components/app-bar";
//import Backdrop from "../../components/backdrop";

export default function HomeLayout(props) {

  return (
    <>
      <AppBar />
      <>
        {/*<Backdrop />*/}
        <Outlet/>
      </>
    </>
  );
}
