import React, { useState } from "react";
import { paths } from "../../common/constants/Rutas";
import useLocalStorage from "../../hooks/useLocalStorage";

export const AppBar = () => {
  const { get } = useLocalStorage();
  const opciones = [
    { id: 1, icono: "home", ruta: paths.inicio, titulo: "Inicio" },
    { id: 2, icono: "event", ruta: paths.sorteos, titulo: "Sorteos" },
    { id: 3, icono: "confirmation_number", ruta: paths.boletos, titulo: "Boletos" },
    { id: 4, icono: "badge", ruta: paths.colaboradores, titulo: "Colaboradores" },
    { id: 5, icono: "contact_support", ruta: paths.contacto, titulo: "Contacto" },
    { id: 6, icono: "remember_me", ruta: paths.miCuenta, titulo: "Mi cuenta", requireLogin: true },
  ]
  const [showOptions, setShowOptions] = useState(false);
  const hash = document.location?.hash.substring(0, (document.location?.hash.indexOf('?') > 0) ? document.location?.hash.indexOf('?') : document.location?.hash.length);
  const pagina = opciones.find(opcion => opcion?.ruta === hash) || opciones[0];

  const onClick = (opcion) => {
    document.location = opcion.ruta;
    document.title = `${opcion.titulo} - La del pesito`;
    setShowOptions(false);
  }

  return (
    <>
      <div className={"appBar"}>
        <button className="appBar-button" onBlur={() => setShowOptions(false)} onClick={() => setShowOptions(!showOptions)} >
          <span className="material-symbols-rounded appBar-menu-icon">menu</span>
        </button>
        <div className="appBar-item">
          <span className="material-symbols-rounded appBar-icon">{pagina?.icono}</span>
          <p className="appBar-titulo">{pagina?.titulo}</p>
        </div>
        <div className="appBar-opciones" style={{height: showOptions ? "220px" : "0px"}} >
        { 
          opciones.filter(opcion => (!opcion.requireLogin || get('_MiCuenta_token', true)) ).map( opcion => 
            <button
              key={opcion.id}
              className="appBar-opcion"
              onClick={() => onClick(opcion)}
            >
              <span className="material-symbols-rounded">{opcion.icono}</span>
              <p className="appBar-opcion-titulo">{opcion.titulo}</p>
            </button>
          ) 
        }
        </div>
      </div>
    </>
  );
};

export default AppBar;
