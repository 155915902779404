import React, { useState } from "react";
import NumberField from "../number-field";
import TextField from"../text-field";
import WebSelect from "../web-select";

export const DynamicControl = (props) => {
  const [isFocused, setIsFocused] = useState(false);
  const handleFocused = (value) => {
    setIsFocused(value);
  }
  return (
    <div 
      key={props.name} 
      className={"control-dinamico"} 
    >
      {props.label && <p className={`etiqueta${isFocused ? " etiqueta-focused" : ""}`}>{props.label} {props.required ? <i style={{color: "red"}}>{"*"}</i> : ''}</p> }
        { props.domain === "numero" && <NumberField {...props} handleFocused={handleFocused} isFocused={isFocused}  />}
        { props.domain === "codigo" && <TextField {...props} handleFocused={handleFocused} isFocused={isFocused} />}
        { props.domain === "select" && <WebSelect {...props} handleFocused={handleFocused} isFocused={isFocused} />}
        { props.domain === "nombre" && <TextField {...props} handleFocused={handleFocused} isFocused={isFocused} />}
        { props.domain === "password" && <TextField {...props} handleFocused={handleFocused} isFocused={isFocused} type={"password"} />}
    </div>
  );
};

export default DynamicControl;
