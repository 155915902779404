import { useLocation } from "react-router-dom";

export const useHashParams = () => {
    const location = useLocation();

    const getHashParam = (value) => {
        let params = {};
        let hash = location?.hash || "";
        let hashHasParams = (location?.hash.indexOf('?') > 0);

        if(hashHasParams) {
            let paramsStr = hash.substring(hash.indexOf('?') + 1, hash.length);
            let paramsArray = paramsStr.split('&');
            
            for(let index in paramsArray){
                let indexArray = paramsArray[index].split('=');
                params[indexArray?.[0] || "unknown"] = indexArray?.[1] || null;
            }
        }
        let result = params[value];
        return result;
    };

    return {
        getHashParam,
    };
};

export default useHashParams;
