import React from "react";

export const Chip = ({
    item
}) => {

  return (
    <div className={"chip"} style={{backgroundColor: item.color}}>{item.desc}</div>
  );
};

export default Chip;
