export const localStorageData = {
    USER: "user",
    SETTINGS: "settings",
    GLOBALES: "globales",
    FETCHED_COUNT: "fetchedCount",
    IS_LANDING_PAGE: "isLandingPage",
    NOTIFICACION_COUNT: "notificacionCount",
    INTERVAL_NOTIFICACIONES: "intervalNotificaciones"
  };
  
  export const intervals = {
    HIDE_SNACKBAR: 5000,
    GET_NEW_NOTIFICATIONS: 10000,
    REFRESH_NOTIFICACIONES: 30000,
  };
  