import React, { useEffect, useState } from "react";
import useHttp from "../../hooks/useHttp";
import useLocalStorage from "../../hooks/useLocalStorage";
import useHashParams from "../../hooks/useHashParams";
import { urls } from "../../common/constants/Rutas";
import { replaceUrl } from "../../common/utilities/urls";
import {
  ActionButton,
  ArrowDown,
  Card,
  DynamicControl,
  Modal,
  Spacer,
} from "../../components";

export const Dashboard = (props) => {
  const { get, set } = useLocalStorage();
  const { getHashParam } = useHashParams();
  const { sendRequest, messages, isLoading } = useHttp();
  const { sendRequest: fetchUser, isLoading: isLoadingUser } = useHttp();
  const [ token, setToken ] = useState(getHashParam("token") || get("token"));
  const [ colaborador, setColaborador ] = useState({});
  const [ loginData, setLoginData] = useState({});
  const [ enableLoginBtn, setEnableLoginBtn] = useState(false);
  const [ opcion, setOpcion] = useState(1);
  const { sendRequest: fetchSorteos } = useHttp();
  const [ sorteos, setSorteos ] = useState([]);
  const [ boletoData, setBoletoData] = useState({});
  const [ enableBoletoBtn, setEnableBoletoBtn] = useState(false);
  const [ telefonoFetched, setTelefonoFetched] = useState("");
  const [ mensaje, setMensaje ] = useState(null);
  const [ existeUsuario, setExisteUsuario] = useState(false);

  useEffect(() => {
    fetchSorteos(
      {
        url: replaceUrl(`${urls.getSorteos}`, {
          todas: "false"
        }),
      },
      (data) => {
        setSorteos(data);
      }
    );
    // eslint-disable-next-line
  }, []);


  // Asigna el token en local storage
  useEffect(() => {
    set("token", token);
    if(token){
      sendRequest(
        {
          url: replaceUrl(`${urls.validaToken}`, {token}),
        },
        (data) => {
          setColaborador(data);
        },
        (error) => {
          setToken('');
        }
      );
    }
    // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    setEnableLoginBtn(loginData?.telefono?.length > 9 && loginData?.password?.length > 5);
  }, [loginData]);

  useEffect(() => {
    setTelefonoFetched(boletoData?.telefono);
    setEnableBoletoBtn(boletoData?.sorteo_id > 0 && boletoData?.telefono?.length > 9 && boletoData?.cantidad > 0);
    // eslint-disable-next-line
  }, [boletoData]);
  
  useEffect(() => {
    if(telefonoFetched?.length > 9) {
      setBoletoData({
        ...boletoData,
        nombre: ''
      });
      setExisteUsuario(false);
      fetchUser(
        {
          url: replaceUrl(`${urls.getUsuarioNombre}`, boletoData),
        },
        (data) => {
          setBoletoData({
            ...boletoData,
            ...data
          });
          setExisteUsuario(data?.nombre);
        }
      );
    }
    // eslint-disable-next-line
  }, [telefonoFetched]);

  const handleLoginControlChange = (e) => {
    let data = { ...loginData };
    data[e?.target?.id] = e?.target?.value.trim();
    setLoginData(data);
  }

  const handleBoletoControlChange = (e) => {
    let data = { ...boletoData };
    data[e?.target?.id] = e?.target?.value.trim();
    setBoletoData(data);
  }

  const handleLogin = (e) => {
    sendRequest(
      {
        url: urls.login,
        method: 'POST',
        body: loginData
      },
      (data) => {
        setToken(data?.uuid);
      }
    );
  }

  const handleBoletoCreate = (e) => {
    let body = {
      ...boletoData,
      vendedor_id: colaborador.colabora_id
    }
    sendRequest(
      {
        url: replaceUrl(`${urls.postBoletos}`, boletoData),
        method: 'POST',
        body
      },
      (data) => {
        console.log('boletoCreatedData: ', data);
        setBoletoData({
          ...boletoData,
          telefono: '',
          nombre: '',
          apePat: '',
          apeMat: '',
          cantidad: ''
        });
        setMensaje({descripcion: "Agregaste el boleto"});
      }
    );
  }

  /** UI */
  return (
    <div className={"boletos"}>
      { !token &&
        <Card titulo={`Inicia Sesión`}>
          <DynamicControl 
            name={"telefono"}
            domain={"numero"}
            label={"Teléfono"}
            onChange={handleLoginControlChange} 
            maxLength={10}
          />
          <DynamicControl 
            name={"password"}
            domain={"password"}
            label={"Constraseña"}
            onChange={handleLoginControlChange}
          />
          <Spacer size={20} />
          <ActionButton
            name={"login"}
            disabled={!enableLoginBtn}
            onClick={handleLogin}
            label={"Iniciar sesión"}
          />
          <Spacer size={5} />
        </Card>
      }
      { token &&
        <>
          <Card titulo={`Bienvenido${colaborador?.nombre ? `, ${colaborador?.nombre}`: ''}!`}>
            <Spacer size={15} />
            {/*<ActionButton
              name={"boleto"}
              onClick={() => setOpcion(1)}
              label={"Nuevo boleto +"}
            />*/}
            <Spacer size={5} />
          </Card>
          <ArrowDown />
          { opcion === 1 &&
            <Card titulo={`Capturar boleto`}>
              <DynamicControl 
                name={"sorteo_id"}
                domain={"select"}
                label={"Sorteo"}
                onChange={handleBoletoControlChange} 
                dataSource={sorteos || []}
                rowKey={"sorteo_id"}
                rowNombre={"nombre"}
                value={boletoData.sorteo_id}
              />
              <DynamicControl 
                name={"telefono"}
                domain={"numero"}
                label={"Teléfono"}
                onChange={handleBoletoControlChange} 
                maxLength={10}
                value={boletoData.telefono || ""}
              />
              { boletoData?.telefono?.length > 9 && !isLoadingUser &&
                <>
                  <DynamicControl 
                    name={"nombre"}
                    domain={"nombre"}
                    label={"Nombre"}
                    readOnly={existeUsuario}
                    value={boletoData.nombre}
                    onChange={handleBoletoControlChange}
                  />
                  { !existeUsuario &&
                    <>
                      <DynamicControl 
                        name={"apePat"}
                        domain={"nombre"}
                        label={"Apellido paterno"}
                        onChange={handleBoletoControlChange} 
                        value={boletoData.apePat}
                      />
                      <DynamicControl 
                        name={"apeMat"}
                        domain={"nombre"}
                        label={"Apellido materno"}
                        onChange={handleBoletoControlChange} 
                        value={boletoData.apeMat}
                      />
                    </>
                  }
                  <DynamicControl 
                    name={"cantidad"}
                    domain={"numero"}
                    label={"Cantidad"}
                    onChange={handleBoletoControlChange} 
                    maxLength={5}
                    value={boletoData.cantidad}
                  />
                </>
              }
              <Spacer size={20} />
              <ActionButton
                name={"boleto"}
                disabled={!enableBoletoBtn}
                onClick={handleBoletoCreate}
                label={"Nuevo boleto +"}
              />
              <Spacer size={5} />
            </Card>
          }
        </>
      }
      <Modal 
        isLoading={isLoading || isLoadingUser} 
        error={messages?.error}
        message={mensaje}
      />
    </div>
  );
};
export default Dashboard;