import { useState } from "react";

const useLoadingContext = () => {

  const [isLoadingContainer, setIsLoadingContainer] = useState(false);

  /** Handle loading container changed */
  const showLoadingContainer = (value) => {
    setIsLoadingContainer(value);
  };

  return {
    isLoadingContainer, 
    showLoadingContainer,
  };
};

export default useLoadingContext;