import appConfig from "../../../config/app";

export const paths = {
  inicio: "#Inicio",
  sorteos: "#Sorteos",
  boletos: "#Boletos",
  colaboradores: "#Colaboradores",
  contacto: "#Contacto",
  miCuenta: "#MiCuenta"
};

export const urls = {
  apiUrl: appConfig.API_URL,
  getUsuarioNombre: appConfig.API_URL + "usuarios/{telefono}",
  getBoletos: appConfig.API_URL + "usuarios/{telefono}/boletos?codigo={codigo}&sorteo={sorteo}",
  getSorteos: appConfig.API_URL + "sorteos?todas={todas}",
  getColaborador: appConfig.API_URL + "colaboradores/{codigo}/validar",
  postBoletos: appConfig.API_URL + "/boletos/{telefono}",
  login: appConfig.API_URL + "login",
  validaToken: appConfig.API_URL + "sesion?token={token}",
};
