import React, { createContext, useEffect, useState } from "react";
import { localStorageData } from "../../common/constants/Settings";

import loadingContext from "./loading";

export const SettingsContext = createContext({});

export const SettingsProvider = (props) => {
  const [settings, setSettings] = useState(
    JSON.parse(localStorage.getItem(localStorageData.SETTINGS) || "{}")
  );

  const { isLoadingContainer, showLoadingContainer } = loadingContext();

  return (
    <SettingsContext.Provider
      value={{
        settings,
        isLoadingContainer
      }}
    >
      {props.children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
