import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useHttp from "../../hooks/useHttp";
import useLocalStorage from "../../hooks/useLocalStorage";
import { urls } from "../../common/constants/Rutas";
import { replaceUrl } from "../../common/utilities/urls";
import {
  ActionButton,
  ArrowDown,
  ColaboradorCard,
  Card,
  DynamicControl,
  Modal,
  NoEncontrado,
  Spacer,
  QrScanner
} from "../../components";

export const Colaborador = (props) => {
  const { get, set } = useLocalStorage();
  const [searchParams] = useSearchParams();
  const { sendRequest, messages, isLoading, clearError } = useHttp();
  const [colaborador, setColaborador] = useState(null);
  const [codigo, setCodigo] = useState(searchParams.get("codigo") || get("codigo") || "");
  const [enableSearch, setEnableSearch] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [fetchedCodigo, setFetchedCodigo] = useState("");

  useEffect(() => {
    set("codigo", codigo);
    setEnableSearch(codigo?.length === 4);
    // eslint-disable-next-line
  }, [codigo]);

  useEffect(() => {
    if (messages)
      setFetched(true);
    // eslint-disable-next-line
  }, [messages]);

  const handleControlChange = (e) => {
    setCodigo(e?.target?.value.trim().toUpperCase());
  }

  const handleOnClick = (e) => {
    setColaborador(null);
    setFetched(false);
    clearError();
    sendRequest(
      {
        url: replaceUrl(`${urls.getColaborador}`, { codigo }),
      },
      (data) => {
        setFetched(true);
        setFetchedCodigo(codigo);
        setColaborador(data.rows[0]);
      }
    );
  }

  /** UI */
  return (
    <div className={"boletos"}>
      <Card titulo={props.titulo}>
        <DynamicControl
          name={"codigo"}
          domain={"codigo"}
          label={"Código"}
          value={codigo}
          onChange={handleControlChange}
          maxLength={4}
        />
        <Spacer size={20} />
        <ActionButton
          name={"consulta"}
          disabled={!enableSearch}
          onClick={handleOnClick}
          label={"Verificar"}
        />
        <Spacer size={5} />
      </Card>
      {fetched && <>
        <ArrowDown />
        {!messages ?
          <ColaboradorCard colaborador={colaborador} codigo={fetchedCodigo} /> :
          <NoEncontrado desc={messages?.error?.nombre} />
        }
      </>
      }
      <QrScanner 
        enabled={true}
        onScan={(data)=>{console.log('Se lee un valor:', data)}}
      />
      <Modal
        isLoading={isLoading}
      />
    </div>
  );
};
export default Colaborador;