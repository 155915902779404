import React, { useEffect, useState } from "react";
import useHttp from "../../hooks/useHttp";
import useLocalStorage from "../../hooks/useLocalStorage";
import useHashParams from "../../hooks/useHashParams";
import { urls } from "../../common/constants/Rutas";
import { replaceUrl } from "../../common/utilities/urls";
import {
  ActionButton,
  ArrowDown,
  Boleto,
  Card,
  DynamicControl,
  Modal,
  NoEncontrado,
  Spacer,
} from "../../components";

export const Boletos = (props) => {
  const { sendRequest, messages, isLoading } = useHttp();
  const { get, set } = useLocalStorage();
  const { getHashParam } = useHashParams();
  const { sendRequest: fetchSorteos, messages: sorteosMessages, isLoading: isLoadingSorteos } = useHttp();
  const [ sorteos, setSorteos ] = useState([]);
  const [ usuarioData, setUsuarioData ] = useState({
    telefono: getHashParam("telefono") || get("telefono"),
    codigo: getHashParam("codigo") || get("codigo")
  });
  const [ enableSearch, setEnableSearch ] = useState(false);
  const [ boletos, setBoletos ] = useState([]);
  const [ fetched, setFetched ] = useState(false);
  let tmpUsuarioData = {};

  useEffect(() => {
    fetchSorteos(
      {
        url: replaceUrl(`${urls.getSorteos}`, {
          todas: "false"
        }),
      },
      (data) => {
        setSorteos(data);
      }
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    set("telefono", usuarioData?.telefono);
    set("codigo", usuarioData?.codigo);
    setEnableSearch( 
      usuarioData?.telefono?.length === 10 
      && usuarioData?.codigo?.length === 5
      && usuarioData?.sorteo
    );
    // eslint-disable-next-line
  }, [usuarioData]);

  const handleControlChange = (e) => {
    tmpUsuarioData = {
      ...usuarioData,
      ...tmpUsuarioData
    }
    tmpUsuarioData[e?.target?.id] = e?.target?.value;
    setUsuarioData(tmpUsuarioData);
  }

  const handleOnClick = (e) => {
    setBoletos([]);
    setFetched(false);
    sendRequest(
      {
        url: replaceUrl(`${urls.getBoletos}`, usuarioData),
      },
      (data) => {
        setFetched(true);
        setBoletos(data?.rows);
      }
    );
  }

  /** UI */
  return (
    <div className={"boletos"}>
      <Card titulo={"Consultar Boletos"}>
        <DynamicControl 
          name={"sorteo"}
          domain={"select"}
          label={"Sorteo"}
          onChange={handleControlChange} 
          dataSource={sorteos || []}
          rowKey={"sorteo_id"}
          rowNombre={"nombre"}
        />
        <DynamicControl 
          name={"telefono"}
          domain={"numero"} 
          label={"Télefono"}
          value={usuarioData?.telefono}
          onChange={handleControlChange}
          maxLength={10}
        />
        <DynamicControl 
          name={"codigo"}
          domain={"numero"}
          label={"Código"}
          value={usuarioData?.codigo}
          onChange={handleControlChange} 
          maxLength={5}
        />
        <Spacer size={20} />
        <ActionButton
          name={"consulta"}
          disabled={!enableSearch}
          onClick={handleOnClick}
          label={"Consultar"}
        />
        <Spacer size={5} />
      </Card>
      { fetched && <>
          <ArrowDown />
          { boletos?.length > 0 ? 
              boletos.map( venta => <Boleto venta={venta} />) : 
              <NoEncontrado desc={"No tienes boletos registrados para este sorteo"} /> 
          }
        </>
      }
      <Modal 
        isLoading={isLoading || isLoadingSorteos} 
        error={messages?.error || sorteosMessages?.error} 
      />
    </div>
  );
};
export default Boletos;